.base {
    z-index: 2;

    .user {
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 8px;
        align-items: center;
    }
}
