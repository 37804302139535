.base {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;

    svg {
        font-size: 36px;
    }

    .text {
        margin-top: 12px;
        font-size: 14px;
    }
}
