.base {
    cursor: pointer;
    color: var(--bp-blue-500);
    font-weight: 500;
    background-color: var(--bp-blue-50);
    padding: 2px 4px;
    border-radius: var(--bp-half-border-radius);
    width: max-content;
    text-decoration: none;
}
