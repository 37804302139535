@import '../../styles/mixins/responsiveness';

.base {
    .top-section {
        position: relative;
        padding: 24px;
        display: grid;
        row-gap: 8px;

        .header {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 38px;
            gap: 16px;

            .title {
                position: relative;

                .completed-indicator,
                .overdue-indicator,
                .moved-indicator {
                    position: absolute;
                    font-size: 20px;
                    top: 2px;
                    transform: translateX(-20px);
                    opacity: 0;
                    transition: var(--bp-transition);
                }

                .completed-indicator,
                .overdue-indicator {
                    z-index: 1;
                }

                .completed-indicator {
                    color: var(--bp-green-700);
                }

                .overdue-indicator {
                    color: var(--bp-red-400);
                    border-radius: 50%;
                    display: flex;
                }

                .moved-indicator {
                    color: var(--bp-purple-500);
                }

                .pet {
                    display: flex;
                    align-items: center;

                    .name {
                        font-weight: 600;
                        font-size: 18px;
                        margin-left: 0;
                        margin-right: 8px;
                        transition: var(--bp-transition);
                    }
                }
            }

            .close-button {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: var(--bp-gray-500);
                padding: 4px;
                border-radius: var(--bp-border-radius);
                margin-right: -4px;
                height: max-content;
                transition: var(--bp-transition);

                @include is-desktop {
                    display: none;
                }

                svg {
                    font-size: 18px;
                }

                .label {
                    font-size: 10px;
                    font-weight: 500;
                }

                &:hover {
                    background-color: var(--bp-gray-25);
                }
            }
        }

        .details {
            display: grid;
            gap: 8px;
            margin-bottom: 12px;
            font-size: 14px;

            .custom-at {
                display: grid;
                grid-template-columns: max-content max-content;
                column-gap: 8px;
                align-items: center;

                svg {
                    margin-left: 8px;
                    margin-right: 4px;
                    margin-bottom: 1px;
                    font-size: 10px;
                    transition: var(--bp-transition);
                }
            }

            .event-form {
                width: initial;
            }
        }

        .complete-button {
            margin-top: 8px;
        }

        .collapse-button {
            cursor: pointer;
            position: absolute;
            font-size: 12px;
            padding: 8px 12px;
            border: 1px solid var(--bp-gray-200);
            background: var(--bp-white);
            box-shadow: var(--bp-box-shadow);
            border-radius: 17px;
            bottom: 0;
            left: calc(50%);
            transform: translate(-50%, 50%);
            z-index: 1;

            svg {
                transform: rotate(-180deg);
                transition: var(--bp-transition);
                margin-right: 4px;
            }

            &.collapsed {
                svg {
                    transform: rotate(0);
                }
            }
        }
    }

    &.completed,
    &.overdue,
    &.moved {
        .top-section {
            .header {
                .title {
                    .pet {
                        .name {
                            margin-left: 24px;
                        }
                    }
                }
            }
        }
    }

    &.completed {
        .top-section {
            .header {
                .completed-indicator {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }

    &.overdue {
        .top-section {
            .header {
                .overdue-indicator {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }

    &.moved {
        .top-section {
            .header {
                .moved-indicator {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }

        &.overdue {
            .top-section {
                .header {
                    .overdue-indicator {
                        left: 7px;
                        top: -2px;
                        font-size: 14px;
                        background-color: var(--bp-red-400);
                        color: var(--bp-white);
                        padding: 1px;
                    }
                }
            }
        }
    }
}
