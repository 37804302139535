.base {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-weight: 600;
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    .body {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        place-items: center;
        margin-top: 12px;
        row-gap: 8px;
        column-gap: 8px;

        .header-cell {
            font-size: 12px;
            color: var(--bp-gray-400);
            font-weight: 500;
        }

        .body-cell {
            font-weight: 500;
            color: var(--bp-gray-400);
            font-size: 12px;
            height: 36px;
            width: 36px;
            display: grid;
            place-items: center;
            border-radius: var(--bp-border-radius);
            transition: var(--bp-transition);
            border: 2px solid transparent;

            &:not(.disabled) {
                cursor: pointer;
            }

            &:not(.outside) {
                background-color: var(--bp-blue-50);
                border-color: var(--bp-blue-50);
            }

            &.today {
                border-color: var(--bp-blue-500);
                color: var(--bp-blue-500);
            }

            &.selected {
                cursor: default;
                border-color: var(--bp-blue-500);
                color: var(--bp-white);
                background-color: var(--bp-blue-500);
                box-shadow: var(--bp-box-shadow);
            }

            &.disabled {
                cursor: not-allowed;

                &:not(.selected) {
                    color: var(--bp-gray-200);
                    background-color: transparent;
                    border-color: transparent;
                }

                &.today {
                    border-color: var(--bp-gray-200);
                }
            }

        }
    }
}
