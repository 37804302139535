%button {
    $horizontal-padding: 40px;
    cursor: pointer;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $horizontal-padding;
    border-radius: var(--bp-border-radius);
    border: 1px solid var(--bp-gray-100);
    box-shadow: var(--bp-box-shadow);
    font-size: inherit;
    font-family: inherit;
    font-weight: 600;
}
