.base {
    $height: 52px;
    $horizontal-padding: 16px;
    width: 100%;

    .body {
        position: relative;
        display: flex;
        align-items: center;
        height: $height;
        border-radius: var(--bp-border-radius);
        border: 1px solid var(--bp-gray-200);
        position: relative;
        font-size: 16px;
        transition: var(--bp-transition);
        box-shadow: var(--bp-box-shadow);

        .border {
            pointer-events: none;
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border: 2px solid transparent;
            border-radius: var(--bp-border-radius);
            z-index: 1;
            transition: var(--bp-transition);
        }

        .input {
            position: relative;
            display: flex;
            width: 100%;
            height: $height;

            .placeholder {
                pointer-events: none;
                position: absolute;
                display: flex;
                color: var(--bp-gray-500);
                transition: var(--bp-transition);
                left: 0;
                font-size: 13px;
                transform: translate(16px, 17px) scale(1);
                transform-origin: 0 0;

                .required-indicator {
                    margin-left: 4px;
                    color: var(--bp-orange-500);
                }
            }

            input {
                display: flex;
                width: 100%;
                border: none;
                font-size: 16px;
                padding: 14px $horizontal-padding 0;
                outline: 0;
                height: calc(100% - 14px);
                border-radius: var(--bp-border-radius);
                font-family: inherit;

                &:focus + .placeholder {
                    transform: translate(16px, 8px) scale(0.77);
                }

                &:-internal-autofill-selected,
                &:-webkit-autofill::first-line,
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    font-size: 16px !important;
                    font-family: var(--bp-font-family) !important;
                }
            }

            &:not(.with-placeholder) {
                input {
                    padding: 7px $horizontal-padding;
                }
            }
        }
    }

    .action {
        cursor: pointer;
        position: absolute;
        color: var(--bp-blue-500);
        right: $horizontal-padding - 8px;
        font-size: 13px;
        font-weight: 600;
        padding: 8px;
        border-radius: var(--bp-border-radius);
        opacity: 0;
        transform: scale(0.9);
        visibility: hidden;
        transition: var(--bp-transition);

        &:hover {
            background-color: var(--bp-gray-50);
        }

        &.shown {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }
    }

    &:not(.empty) {
        .body {
            .input {
                .placeholder {
                    transform: translate(16px, 8px) scale(0.77);
                }
            }
        }
    }

    &.focused {
        .body {
            .border {
                border-color: var(--bp-blue-500);
            }
        }
    }

    &.invalid {
        .body {
            .border {
                border-color: var(--bp-red-500);
            }
        }

        .invalid-indicator {
            margin: 4px 0;
        }
    }

    .invalid-indicator {
        color: var(--bp-red-400);
        font-size: 13px;
    }

    & + & {
        margin-top: 16px;
    }
}
