.base {
    $height: 76px;
    $horizontal-padding: 16px;
    position: relative;
    display: flex;
    align-items: flex-start;
    height: $height;
    border-radius: var(--bp-border-radius);
    border: 1px solid var(--bp-gray-200);
    position: relative;
    font-size: 16px;
    box-shadow: var(--bp-box-shadow);
    width: calc(100% - 3px);

    .border {
        pointer-events: none;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 2px solid transparent;
        border-radius: var(--bp-border-radius);
        z-index: 1;
        transition: var(--bp-transition);
    }

    &.focused {
        .border {
            border-color: var(--bp-blue-500);
        }
    }

    .placeholder {
        pointer-events: none;
        position: absolute;
        display: flex;
        color: var(--bp-gray-500);
        transition: var(--bp-transition);
        left: 0;
        top: 0;
        font-size: 13px;
        transform: translate(16px, 17px) scale(1);
        transform-origin: 0 0;

        .required-indicator {
            margin-left: 4px;
            color: var(--bp-orange-500);
        }
    }

    &:not(.empty) {
        .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }
    }

    textarea {
        display: flex;
        width: 100%;
        border: none;
        font-size: 16px;
        padding: 24px $horizontal-padding 0;
        outline: 0;
        height: calc(100% - 24px);
        border-radius: var(--bp-border-radius);
        resize: none;
        font-family: inherit;

        &:focus + .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }
    }

    &.disabled {
        textarea {
            background-color: var(--bp-gray-25);
            color: var(--bp-gray-400);
        }
    }

    &:not(.with-placeholder) {
        textarea {
            padding: 12px $horizontal-padding;
        }
    }
}
