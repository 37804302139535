.base {
    position: relative;
    border-radius: var(--bp-border-radius);
    height: 38px;
    background-color: var(--bp-gray-50);
    box-shadow: var(--bp-box-shadow);
    border: 1px solid var(--bp-gray-100);
    display: flex;
    padding: 8px;

    .selected-indicator {
        position: absolute;
        border-radius: var(--bp-border-radius);
        background-color: var(--bp-blue-500);
        transition: var(--bp-bounce-transition);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
    }

    .option {
        position: relative;
        font-weight: 600;
        padding: 8px 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: var(--bp-gray-400);
        transition: var(--bp-transition);
        z-index: 0;
        text-align: center;

        &.selected {
            color: var(--bp-white);
        }

        &:not(.selected) {
            cursor: pointer;
        }

        & + .option {
            margin-left: 20px;
        }
    }
}
