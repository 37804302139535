@import '../../../styles/mixins/viewport-height';

.base {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include viewport-height(100, 116px);

    .user-image-input {
        margin-bottom: 16px;
    }

    .submit-button {
        margin-top: 16px;
    }

    .logout-button {
        width: calc(100% - 80px);
        margin-top: 16px;
    }
}
