.base {
    font-size: 13px;
    padding: 16px 24px 24px;
    overflow: auto;
    border-top: 1px solid var(--bp-gray-200);
    transition: var(--bp-transition);

    .order {
        padding: 8px 0 24px 24px;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: 2px solid var(--bp-gray-300);
            position: absolute;
            left: 0;
            top: 12px;
            background-color: var(--bp-white);
            z-index: 1;
        }

        &::after {
            content: '';
            left: 5px;
            top: 0;
            bottom: 0;
            border: 1px solid var(--bp-gray-100);
            position: absolute;
        }

        &:first-child {
            &::after {
                top: 12px;
            }
        }

        &:last-child {
            &::after {
                bottom: initial;
                height: 10px;
            }
        }
    }
}
