@mixin viewport-height($percentage, $offset: 0px) {
    height: calc(var(--vh, 1vh) * #{$percentage} - #{$offset});
}

@mixin viewport-min-height($percentage, $offset: 0px) {
    min-height: calc(var(--vh, 1vh) * #{$percentage} - #{$offset});
}

@mixin viewport-max-height($percentage, $offset: 0px) {
    max-height: calc(var(--vh, 1vh) * #{$percentage} - #{$offset});
}
