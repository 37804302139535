.base {
    position: fixed;
    width: max-content;
    left: 50%;
    bottom: 32px;
    display: flex;
    transform: translateX(-50%);
    padding: 8px;
    background-color: var(--bp-gray-50);
    border-radius: var(--bp-border-radius);
    border: 1px solid var(--bp-gray-100);
    box-shadow: var(--bp-box-shadow);

    .selected-indicator {
        position: absolute;
        border-radius: var(--bp-border-radius);
        background-color: var(--bp-blue-500);
        transition: var(--bp-bounce-transition);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
    }

    .action {
        color: var(--bp-black);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
        border-radius: var(--bp-border-radius);
        transition: var(--bp-transition);
        min-width: 38px;
        min-height: 38px;
        z-index: 1;

        &:not(.selected):hover {
            background-color: var(--bp-gray-100);
        }

        svg {
            margin: auto 0;
        }

        .label {
            font-size: 10px;
            font-weight: 500;
            color: var(--bp-gray-500);
            margin-top: 4px;
            transition: var(--bp-transition);
            white-space: nowrap;
        }

        &.selected {
            color: var(--bp-white);

            .label {
                color: var(--bp-white);
            }
        }

        & + .action {
            margin-left: 20px;
        }
    }
}
