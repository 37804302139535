.base {
    $height: 52px;
    $horizontal-padding: 16px;
    position: relative;
    display: flex;
    align-items: center;
    height: $height;
    border-radius: var(--bp-border-radius);
    border: 1px solid var(--bp-gray-200);
    position: relative;
    box-shadow: var(--bp-box-shadow);
    font-size: 16px;
    width: calc(100% - 3px);

    .border {
        pointer-events: none;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 2px solid transparent;
        border-radius: var(--bp-border-radius);
        z-index: 1;
        transition: var(--bp-transition);
    }

    .input {
        display: flex;
        width: 100%;
        border: none;
        font-size: 16px;
        outline: 0;
        height: 100%;

        &:focus + .text-input__placeholder {
            font-size: 10px;
            top: 8px;
        }
    }

    .label {
        pointer-events: none;
        position: absolute;
        padding: 14px ($horizontal-padding * 2 + 24px) 0 16px;
        display: flex;
        white-space: nowrap;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        overflow: hidden;
    }

    .placeholder {
        pointer-events: none;
        position: absolute;
        display: flex;
        color: var(--bp-gray-500);
        transition: var(--bp-transition);
        left: 0;
        font-size: 13px;
        top: 0;
        transform: translate(16px, 17px) scale(1);
        transform-origin: 0 0;

        .required-indicator {
            margin-left: 4px;
            color: var(--bp-orange-500);
        }
    }

    &:not(.empty) {
        .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }
    }

    &.open {
        .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }

        .open-indicator {
            transform: rotate(180deg);
        }
    }

    .clear {
        cursor: pointer;
        position: absolute;
        right: 48px - $horizontal-padding;
        background: var(--bp-white);
        padding: 0 $horizontal-padding;
        top: 2px;
        bottom: 2px;
        display: grid;
        place-items: center;
        z-index: 1;
        color: var(--bp-gray-600);
        transition: var(--bp-transition);

        &:not(.shown) {
            visibility: hidden;
            opacity: 0;
        }
    }

    .open-indicator {
        pointer-events: none;
        position: absolute;
        right: 0;
        padding: 0 $horizontal-padding;
        background: var(--bp-white);
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        border-radius: var(--bp-border-radius);
        transition: var(--bp-transition);

        svg {
            font-size: 14px;
        }
    }

    select {
        opacity: 0;
    }

    &.disabled {
        .open-indicator {
            background-color: transparent;
            color: var(--bp-gray-300);
        }
    }

    &.focused {
        .border {
            border-color: var(--bp-blue-500);
        }
    }

    &:not(.empty) {
        .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }
    }

    &.open {
        .placeholder {
            transform: translate(16px, 8px) scale(0.77);
        }

        .open-indicator {
            transform: rotate(180deg);
        }
    }
}
