.base {
    background-position: center;
    background-size: cover;
    border-radius: var(--bp-double-border-radius);
    opacity: 0;
    transform: scale(0.7);
    flex-shrink: 0;
    background-color: var(--bp-gray-200);
    transition: var(--bp-transition);

    img {
        display: none;
    }

    &.shown {
        opacity: 1;
        transform: scale(1);
    }
}
