.context-message {
    position: fixed;
    inset: 0;
    top: 16px;
    width: max-content;
    height: max-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    visibility: hidden;
    z-index: 101;

    .message {
        display: flex;
        gap: 8px;
        align-items: center;
        background: var(--bp-gray-900);
        color: var(--bp-white);
        border-radius: 21px;
        padding: 8px;
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        transform: translateY(-16px);
        transition: visibility var(--bp-transition), opacity var(--bp-transition), transform var(--bp-transition),
            max-width 0.2s ease;
        overflow: hidden;

        .emoji {
            font-size: 12px;
            margin-right: 16px;
            margin-left: 4px;
        }

        .text {
            white-space: nowrap;
        }
    }

    .close {
        cursor: pointer;
        margin-left: 8px;
        padding: 4px;
        border-radius: var(--bp-border-radius);
        font-size: 16px;
        transition: var(--bp-transition);

        &:hover {
            background-color: var(--bp-gray-600);
        }
    }

    &.shown {
        visibility: visible;

        .message {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}
