@import '../../../styles/mixins/viewport-height';
@import '../../../styles/mixins/responsiveness';

.base {
    display: flex;
    flex-direction: column;
    padding: 12px;
    @include viewport-height(100, 128px);

    .search-form {
        width: 100%;

        @include is-desktop {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .filter {
                width: auto;
                margin: 0;

                + .filter {
                    margin-left: 12px;
                }
            }
        }

        @include is-mobile {
            display: flex;
            width: 100%;
        }
    }

    .table {
        $grid-template-columns: 72px 140px 160px 120px 1fr 120px;
        $mobile-grid-template-columns: 72px 140px 1fr;
        height: 100%;
        overflow: hidden;
        border-radius: var(--bp-border-radius);
        margin-top: 12px;

        .headers {
            border: 1px solid var(--bp-gray-100);
            display: grid;
            grid-template-columns: $grid-template-columns;
            overflow-y: scroll;
            margin-right: -2px;
            border-top-left-radius: var(--bp-border-radius);
            border-top-right-radius: var(--bp-border-radius);
            width: calc(100% - 2px);

            @include is-mobile {
                grid-template-columns: $mobile-grid-template-columns;
            }

            .header {
                padding: 8px;
                font-size: 11px;
                font-weight: 500;
                text-transform: uppercase;
                color: var(--bp-gray-400);

                &.centered {
                    display: flex;
                    justify-content: center;
                }

                &.right {
                    display: flex;
                    justify-content: flex-end;
                }

                & + .header {
                    border-left: 1px solid var(--bp-gray-100);
                }
            }
        }

        .body {
            border: 1px solid var(--bp-gray-100);
            border-top: 0;
            border-bottom-left-radius: var(--bp-border-radius);
            border-bottom-right-radius: var(--bp-border-radius);
            overflow-y: scroll !important;

            .row {
                cursor: pointer;
                font-size: 14px;
                display: grid;
                grid-template-columns: $grid-template-columns;
                transition: background-color var(--bp-transition);

                + .row {
                    border-top: 1px solid var(--bp-gray-100);
                }

                @include is-mobile {
                    grid-template-columns: $mobile-grid-template-columns;
                }

                @include is-desktop {
                }

                .cell {
                    padding: 8px;
                    align-items: center;
                    display: flex;
                    overflow: hidden;

                    .products-formatter {
                        display: flex;
                        column-gap: 8px;
                        row-gap: 8px;
                        flex-wrap: wrap;
                        height: 100%;

                        .product {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            b {
                                color: var(--bp-gray-600);
                            }
                        }
                    }

                    &.centered {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }

                    &.right {
                        display: flex;
                        justify-content: flex-end;
                    }

                    & + .cell {
                        border-left: 1px solid var(--bp-gray-100);
                    }
                }

                &.odd {
                    background-color: var(--bp-gray-25);
                }

                &:hover {
                    background-color: var(--bp-gray-50);
                }
            }
        }
    }
}
