@import '../../styles/mixins/viewport-height';
@import '../../styles/mixins/responsiveness';

.base {
    position: relative;
    transform: translate(0);
    transition: var(--bp-transition);

    .body {
        @include viewport-height(100, 30px);
        max-width: 1200px;
        margin: 0 auto;
    }

    .mask {
        cursor: pointer;
        z-index: 100;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        transition: var(--bp-transition);
    }

    &.blurred {
        //filter: blur(4px);

        @include is-desktop {
            //transform: translateX(24px);
        }

        @include is-mobile {
            //transform: translateY(-24px);
        }

        .mask {
            visibility: visible;
            opacity: 1;
        }
    }
}
