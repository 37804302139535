@import '../../styles/mixins/responsiveness';

.base {
    .top-section {
        padding: 24px;

        .header {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 38px;
            gap: 16px;
            margin-bottom: 8px;

            .name {
                font-weight: 600;
                font-size: 18px;
                margin-left: 0;
                transition: var(--bp-transition);
            }

            .close-button {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: var(--bp-gray-500);
                padding: 4px;
                border-radius: var(--bp-border-radius);
                margin-right: -4px;
                height: max-content;
                transition: var(--bp-transition);

                @include is-desktop {
                    display: none;
                }

                svg {
                    font-size: 18px;
                }

                .label {
                    font-size: 10px;
                    font-weight: 500;
                }

                &:hover {
                    background-color: var(--bp-gray-25);
                }
            }
        }

        .details {
            display: grid;
            gap: 8px;
            margin-bottom: 12px;
            font-size: 14px;
        }
    }
}
