@import '../../styles/mixins/responsiveness';
@import '../../styles/mixins/viewport-height';

.base {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    @include viewport-height(100);

    @include is-mobile {
        flex-direction: column;

        .logo {
            margin-bottom: 16px;
        }
    }

    .submit-button {
        margin-top: 16px;
    }
}
