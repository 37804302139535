.base {
    padding: 3px 5px;
    border-radius: var(--bp-half-border-radius);
    font-weight: 600;
    font-size: 11px;
    width: max-content;
    height: max-content;
    position: relative;

    &.gray {
        background-color: var(--bp-gray-50);
        color: var(--bp-gray-400);
    }

    &.green {
        background-color: var(--bp-green-100);
        color: var(--bp-green-800);
    }

    &.yellow {
        background-color: var(--bp-yellow-100);
        color: var(--bp-yellow-800);
    }

    &.red {
        background-color: var(--bp-red-100);
        color: var(--bp-red-800);
    }

    &.blue {
        background-color: var(--bp-blue-100);
        color: var(--bp-blue-800);
    }

    .type-indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        position: absolute;
        right: -4px;
        top: -4px;

        &.warning {
            background-color: var(--bp-orange-500);
            box-shadow: var(--bp-box-shadow);
        }
    }
}
