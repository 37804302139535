.order-summary {
    position: relative;

    &.simple {
        .details {
            .detail {
                font-size: 14px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .date {
            font-weight: 500;
            display: grid;

            .pet-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .details {
        margin-bottom: 8px;

        .detail {
            font-size: 12px;

            .label {
                font-size: 11px;
                color: var(--bp-gray-500);
                margin-bottom: 2px;
            }

            & + .detail {
                margin-top: 8px;
            }
        }
    }

    .products {
        font-size: 12px;
        margin-top: 4px;
        display: grid;
        gap: 4px;

        .product {
            display: grid;
            grid-template-columns: max-content 1fr;
            gap: 8px;
            align-items: center;
        }
    }
}
