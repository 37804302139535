@import '../../../styles/mixins/viewport-height';
@import '../../../styles/mixins/responsiveness';

.base {
    display: flex;
    flex-direction: column;
    padding: 12px;
    @include viewport-height(100, 128px);

    .search-form {
        @include is-mobile {
            width: 100%;
        }
    }

    .table {
        $grid-template-columns: 1fr 180px 180px 160px;
        height: 100%;
        overflow: hidden;
        border-radius: var(--bp-border-radius);
        margin-top: 12px;

        .headers {
            border: 1px solid var(--bp-gray-100);
            display: grid;
            grid-template-columns: $grid-template-columns;
            overflow-y: scroll;
            margin-right: -2px;
            border-top-left-radius: var(--bp-border-radius);
            border-top-right-radius: var(--bp-border-radius);
            width: calc(100% - 2px);

            .header {
                padding: 8px;
                font-size: 11px;
                font-weight: 500;
                text-transform: uppercase;
                color: var(--bp-gray-400);

                &.centered {
                    display: flex;
                    justify-content: center;
                }

                & + .header {
                    border-left: 1px solid var(--bp-gray-100);
                }
            }
        }

        .body {
            border: 1px solid var(--bp-gray-100);
            border-top: 0;
            border-bottom-left-radius: var(--bp-border-radius);
            border-bottom-right-radius: var(--bp-border-radius);
            overflow-y: scroll !important;

            .row {
                cursor: pointer;
                font-size: 14px;
                display: grid;
                grid-template-columns: $grid-template-columns;
                transition: background-color var(--bp-transition);

                .cell {
                    padding: 8px;
                    align-items: center;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    .client-formatter {
                        display: grid;

                        .name {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .email {
                            font-size: 12px;
                            color: var(--bp-gray-400);
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    &.centered {
                        display: flex;
                        justify-content: center;
                    }

                    & + .cell {
                        border-left: 1px solid var(--bp-gray-100);
                    }
                }

                &.odd {
                    background-color: var(--bp-gray-25);
                }

                &:hover {
                    background-color: var(--bp-gray-50);
                }

                & + .row {
                    border-top: 1px solid var(--bp-gray-100);
                }
            }
        }
    }
}
