.base {
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    transition: var(--bp-transition);

    .body {
        width: 100%;
    }

    &.collapsed {
        opacity: 0;
        margin: 0 !important;
        transform: translateY(-8px);
    }
}
