@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap');

:root {
    --bp-blue-900:      #023C60;
    --bp-blue-800:      #034A77;
    --bp-blue-700:      #03598F;
    --bp-blue-600:      #0468A7;
    --bp-blue-500:      #0477BF;
    --bp-blue-400:      #3692CC;
    --bp-blue-300:      #68ADD9;
    --bp-blue-200:      #9BC9E5;
    --bp-blue-100:      #CDE4F2;
    --bp-blue-50:       #F5F7FF;

    --bp-purple-900:    #3B367B;
    --bp-purple-800:    #49449A;
    --bp-purple-700:    #5851B9;
    --bp-purple-600:    #665FD7;
    --bp-purple-500:    #756CF6;
    --bp-purple-400:    #8E87F8;
    --bp-purple-300:    #A7A1F9;
    --bp-purple-200:    #C0BBFB;
    --bp-purple-100:    #D8D6FD;
    --bp-purple-50:     #F1F0FE;

    --bp-orange-800:    #FF8800;
    --bp-orange-600:    #D58700;
    --bp-orange-500:    #F23E16;
    --bp-orange-400:    #F6A623;
    --bp-orange-200:    #F29B88;
    --bp-orange-50:     #FEF6E8;


    --bp-yellow-500:    #806104;
    --bp-yellow-800:    #9F7904;
    --bp-yellow-700:    #BF9105;
    --bp-yellow-600:    #DFA906;
    --bp-yellow-500:    #FFC107;
    --bp-yellow-400:    #FFCC34;
    --bp-yellow-300:    #FFD760;
    --bp-yellow-200:    #FFE38D;
    --bp-yellow-100:    #FFEEBA;
    --bp-yellow-50:     #FFF9E6;

    --bp-red-900:       #7D2222;
    --bp-red-800:       #9C2B2B;
    --bp-red-700:       #BC3333;
    --bp-red-600:       #DB3C3C;
    --bp-red-500:       #FA4444;
    --bp-red-400:       #FB6666;
    --bp-red-300:       #FC8787;
    --bp-red-200:       #FDA9A9;
    --bp-red-100:       #FECBCB;
    --bp-red-50:        #FFECEC;

    --bp-green-900:     #2C7651;
    --bp-green-800:     #369365;
    --bp-green-700:     #41B079;
    --bp-green-600:     #4CCE8D;
    --bp-green-500:     #57EBA1;
    --bp-green-400:     #75EFB2;
    --bp-green-300:     #94F2C3;
    --bp-green-200:     #B2F6D4;
    --bp-green-100:     #D0F9E5;
    --bp-green-50:      #EEFDF6;

    --bp-white:         #FFFFFF;
    --bp-black:         #000000;
    --bp-gray-900:      #2B2C38;
    --bp-gray-800:      #353646;
    --bp-gray-700:      #404154;
    --bp-gray-600:      #4A4C62;
    --bp-gray-500:      #555770;
    --bp-gray-400:      #74758A;
    --bp-gray-300:      #9294A4;
    --bp-gray-200:      #B1B2BD;
    --bp-gray-100:      #CFD0D7;
    --bp-gray-50:       #EEEEF1;
    --bp-gray-25:       #F8F8FA;

    $transition-duration: 0.3s;
    $border-radius: 10px;
    --bp-border-radius: #{$border-radius};
    --bp-half-border-radius: calc(#{$border-radius} / 2);
    --bp-transition: #{$transition-duration} ease;
    --bp-bounce-transition: #{$transition-duration} cubic-bezier(0.71, -0.13, 0.41, 1.27);
    --bp-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
    --bp-font-family: 'Lexend';
}

html {
    overflow: hidden;

    body {
        margin: 0;
        font-family: var(--bp-font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: var(--bp-transition);

        #⚛ {
            overflow: hidden;
        }

        &.shown {
            opacity: 1 !important;
        }

        input {
            font-family: inherit;
        }
    }
}
