.base {
    border-radius: 50%;

    .fallback {
        border-radius: 50%;
        background-color: var(--bp-gray-50);
        color: var(--bp-black);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 70%;
        font-weight: 500;
    }
}
