.base {
    $size: 14px;
    min-width: $size;
    min-height: $size;
    border: 3px solid transparent;
    border-right: 3px solid var(--bp-white);
    border-bottom: 3px solid var(--bp-white);
    border-radius: 50%;
    animation: spinner 0.7s ease infinite;
    width: max-content;

    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
