.base {
    color: var(--bp-gray-400);
    font-size: 11px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin: 0 4px;
        font-size: 9px;
    }
}
