.base {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    transition: var(--bp-transition);

    .mask {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--bp-white);
        background-color: rgba(0, 0, 0, 0.2);
        font-size: 12px;
        opacity: 0;
        font-weight: 500;
        transition: var(--bp-transition);
    }

    input {
        display: none;
    }

    &:hover {
        .image {
            filter: blur(4px);
        }

        .mask {
            opacity: 1;
        }
    }
}
