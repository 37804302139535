@import '../../styles/mixins/responsiveness';

.base {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: var(--bp-white);
    z-index: 100;
    border: 1px solid var(--bp-gray-100);
    box-shadow: 4px 0 8px rgba(0, 0, 0, 0.03%);
    opacity: 0;
    visibility: hidden;
    transition: var(--bp-transition);

    @include is-desktop {
        width: 400px;

        &.right {
            transform: translateX(24px);
        }

        &.left {
            transform: translateX(-24px);
        }
    }

    @include is-mobile {
        transform: translateY(24px);
        width: 100%;
    }

    &.right {
        right: 0;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    &.left {
        left: 0;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    &.open {
        opacity: 1;
        visibility: visible;
        transform: translate(0);
    }
}
