@import '../../styles/classes/button';

.base {
    $loading-indicator-size: 14px;
    $margin: 16px;
    @extend %button;
    position: relative;
    transition: background-color var(--bp-transition), color var(--bp-transition);

    .content {
        transform: translateX(0);
        transition: transform var(--bp-transition);
        display: flex;
    }

    .loading-indicator {
        position: absolute;
        transform: translateX(-($loading-indicator-size + $margin));
        opacity: 0;
        transition: var(--bp-transition);

        .spinner {
            transform: translateX(-#{$loading-indicator-size});
        }
    }

    &.disabled,
    &.loading {
        background-color: var(--bp-gray-50) !important;
        color: var(--bp-gray-300) !important;

        .loading-indicator {
            .spinner {
                border-right: 3px solid var(--bp-gray-300);
                border-bottom: 3px solid var(--bp-gray-300);
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
    }

    &.loading {
        cursor: progress;

        .content {
            transform: translateX($loading-indicator-size);
        }

        .loading-indicator {
            transform: translateX(-$margin);
            opacity: 1;
        }
    }

    &.squared {
        width: 52px;
        padding: 0;
    }
}
