.base {
    $decorator-height: 3px;
    cursor: pointer;
    position: relative;
    border: 1px solid var(--bp-gray-100);
    border-top: 1px solid transparent;
    font-size: 12px;
    border-radius: var(--bp-half-border-radius);
    padding: (4px + $decorator-height) 8px 4px;
    background-color: var(--bp-white);
    overflow: hidden;
    max-width: 128px;

    .header {
        position: relative;
        padding-left: 0;
        transition: var(--bp-transition);
        display: grid;
        grid-template-columns: 1fr max-content;
        column-gap: 4px;
        align-items: center;

        .completed-indicator,
        .overdue-indicator,
        .moved-indicator {
            font-size: 14px;
            position: absolute;
            left: -16px;
            top: 4px;
            opacity: 0;
            transition: var(--bp-transition);
        }

        .completed-indicator,
        .overdue-indicator {
            z-index: 1;
        }

        .id {
            font-weight: 600;
        }

        .days-between {
            margin-left: auto;
        }
    }

    .client {
        font-size: 11px;
    }

    .phone-number {
        font-size: 11px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $decorator-height;
        background-color: var(--bp-gray-100);
    }

    &.completed {
        .header {
            padding-left: 18px;

            .completed-indicator {
                left: 0;
                opacity: 1;
                color: var(--bp-green-700);
            }
        }

        &::before {
            background-color: var(--bp-green-700);
        }
    }

    &.overdue {
        .header {
            padding-left: 18px;

            .overdue-indicator {
                left: 0;
                opacity: 1;
                color: var(--bp-red-400);
                border-radius: 50%;
                display: flex;
            }
        }

        &::before {
            background-color: var(--bp-red-400) !important;
        }
    }

    &.moved {
        .header {
            padding-left: 18px;

            .moved-indicator {
                left: 0;
                opacity: 1;
                color: var(--bp-purple-500);
            }
        }

        &.overdue {
            .header {
                .overdue-indicator {
                    left: 5px;
                    top: -1px;
                    font-size: 11px;
                    padding: 1px;
                    background-color: var(--bp-red-400);
                    color: var(--bp-white);
                }
            }
        }

        &::before {
            background-color: var(--bp-purple-500);
        }
    }
}
