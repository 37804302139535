.base {
    font-size: 14px;
    width: 100%;

    .label {
        font-size: 11px;
        color: var(--bp-gray-500);
        margin-bottom: 2px;
    }
}
