@import '../../../styles/mixins/viewport-height';
@import '../../../styles/mixins/responsiveness';

%progress {
    margin-top: 4px;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 8px;
    width: 100%;
    align-items: center;
    opacity: 0;
    transition: var(--bp-transition);

    .label {
        grid-column: 1 / 3;
        font-size: 11px;
        color: var(--bp-gray-400);
        margin-bottom: 2px;
    }

    .progress-bar {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: var(--bp-gray-100);
        border-radius: 2px;

        .progress-indicator {
            position: absolute;
            left: 0;
            height: 3px;
            background-color: var(--bp-blue-500);
            border-radius: 2px;
            width: 0;
            transition: var(--bp-transition);
        }
    }

    .count {
        display: flex;
        font-size: 11px;
        font-weight: 500;
        color: var(--bp-blue-500);

        .completed {
            margin-right: 4px;
        }
    }

    &.shown {
        opacity: 1;
    }
}

.base {
    padding: 12px;

    .toolbar {
        margin-bottom: 12px;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: center;

        @include is-mobile {
            flex-direction: column-reverse;

            .buttons {
                width: 100%;

                .button:last-child {
                    margin-left: auto;
                }
            }

            .date {
                margin-bottom: 12px;
            }

            .toggle {
                display: none;
            }
        }

        .buttons {
            display: flex;
            gap: inherit;

            .button {
                width: max-content;

                &.squared {
                    padding: 0 21px;
                }
            }
        }

        .date {
            font-size: 22px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }

    .calendar {
        @include viewport-max-height(100, 196px);
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        border: 1px solid var(--bp-gray-100);
        border-radius: var(--bp-border-radius);
        overflow: auto;

        @include is-mobile {
            @include viewport-max-height(100, 246px);
        }

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px;
            position: sticky;
            top: 0;
            background: var(--bp-white);
            z-index: 2;
            border-bottom: 1px solid var(--bp-gray-100);
            min-width: 100px;

            .day-label {
                text-transform: capitalize;
                font-size: 11px;
                font-weight: 500;
                color: var(--bp-gray-400);
            }

            .day-number {
                font-size: 22px;
                font-weight: 600;
                line-height: 20px;
            }

            .progress {
                @extend %progress;
            }

            &.today {
                background-color: var(--bp-blue-50);

                .day-label,
                .day-number {
                    color: var(--bp-blue-500);
                }
            }

            &:first-child {
                left: 0;
                z-index: 3;
                border-right: 1px solid var(--bp-gray-100);
                border-top-left-radius: var(--bp-border-radius);
            }

            & + .header {
                border-left: 1px solid var(--bp-gray-100);
            }
        }

        .user-cell {
            border-top: 1px solid var(--bp-gray-100);
            border-right: 1px solid var(--bp-gray-100);
            padding: 8px;
            position: sticky;
            left: 0;
            background: var(--bp-white);
            z-index: 1;

            .user {
                display: grid;
                grid-template-columns: 28px 1fr;
                column-gap: 8px;
                align-items: center;

                .details {
                    display: grid;
                    grid-template-rows: 15px 13px;

                    .name {
                        font-size: 12px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-weight: 500;
                    }

                    .id {
                        font-size: 10px;
                        color: var(--bp-gray-400);
                        font-weight: 500;
                    }
                }

                .progress {
                    @extend %progress;
                    grid-column: 1 / 3;
                    margin-top: 8px;
                }

                &.no-image {
                    grid-template-columns: 1fr;

                    .image {
                        display: none;
                    }
                }
            }
        }

        .cell {
            padding: 4px;
            min-height: 120px;
            border-left: 1px solid var(--bp-gray-100);
            border-top: 1px solid var(--bp-gray-100);

            .event-card {
                box-shadow: var(--bp-box-shadow);

                &.selected {
                    position: relative;
                    z-index: 101;
                }

                & + .event-card {
                    margin-top: 4px;
                }
            }

            &.today {
                background-color: var(--bp-blue-50);
            }
        }
    }
}
